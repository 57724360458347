import React, {Fragment} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import {useQuery} from "@apollo/client";

import Modal from "./bootstrap/modal";
import Terms from "../components/portal/terms";
import PrivacyPolicy from "../components/portal/privacy-policy";
import {getFormData} from "./manager/form";
import {getProductsQuery, getProductsResult} from "./logic/products";

const Review = (props) => {
  const {
    onClose,
    submit,
    product,
    purchaseError,
    firstName,
    lastName,
    email,
    mobile,
    cardHolderName,
    cardNumber,
    cardExpiryMonth,
    cardExpiryYear,

    unitNo,
    streetNo,
    suburb,
    state,
    postCode,

    unitNoShipping,
    streetNoShipping,
    addressShipping,
    suburbShipping,
    stateShipping,
    postCodeShipping,
    handleReturnToProductSelector
  } = props;

  const [loading, setLoading] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);
  const [checked, setCheck] = useAsyncSetState(false);
  const [consent, setConsent] = useAsyncSetState(false);

  const {
    serviceAvailability,
    newConnectionProduct,
    selectedProducts,
    building,
    voucherCode,
  } = getFormData();

  const pageInfoQuery = useQuery(getProductsQuery, {
    buildingCode: building.code,
    voucherCode,
    showHidden: true,
  });

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const products = getProductsResult(pageInfoQuery);

  const plan = selectedProducts.filter(({type}) => type === "internet");

  const hardwareProductsDisplay = selectedProducts.filter(
    ({type}) => type === "hardware"
  );

  const addOnProductsDisplay = selectedProducts.filter(
    ({type}) => !["hardware", "internet", "generic"].includes(type)
  );

  let newConnectionProducts = [];

  if (serviceAvailability?.isNewConnection) {
    newConnectionProducts = products.filter(
      (pp) =>
        !selectedProducts.map((sp) => sp.id).includes(pp.id) &&
        pp.onlyNewConnection
    );
  }

  const connectionFee = newConnectionProducts.reduce(
    (c, ncp) => c + parseFloat(ncp.value),
    0
  );

  const total =
    connectionFee + (selectedProducts || []).reduce((o, p) => p.value + o, 0);

  const perMonthWithVoucher = (selectedProducts || [])
    .filter(
      (p) => ["voip", "staticip", "internet"].indexOf(p.type) > -1 || !p.once
    )
    .reduce((o, p) => p.value + o, 0);

  const originalPerMonth = (selectedProducts || [])
    .filter(
      (p) => ["voip", "staticip", "internet"].indexOf(p.type) > -1 || !p.once
    )
    .reduce((o, p) => (p.voucherName ? p.originalValue : p.value) + o, 0);

  const renderAcknowledgeText = () => {
    let ack = "";

    if (connectionFee > 0) {
      ack += `I acknowledge I will be charged $${connectionFee} for the New Development Charge in the first month in addition to my plan charge/s. `;
    }
    if (product.voucherName && product.voucherUses > 1) {
      ack += `I acknowledge that I will be charged plan charges of $${perMonthWithVoucher} per month for the first ${
        product.voucherUses
      } month/s and then $${originalPerMonth} per month, until cancelled. (First month: $${total}, next ${
        product.voucherUses - 1
      } month/s: $${perMonthWithVoucher}, thereafter: $${originalPerMonth})`;
    } else if (product.voucherName && product.voucherUses === 1) {
      ack += `I acknowledge that I will be charged plan charges of $${perMonthWithVoucher} per month for the first month and then $${originalPerMonth} per month, 
      until cancelled. (First month: $${total}, thereafter: $${originalPerMonth})`;
    } else {
      ack += `I acknowledge that I will be charged plan charges of $${originalPerMonth} per month, until cancelled. (First month: $${total}, thereafter: $${originalPerMonth})`;
    }

    return ack;
  };

  const handleCloseModal = () => {
    onClose && onClose();
  };

  const handleClickPurchase = async () => {
    await setLoading(true);

    if (submit) {
      await submit();
    }

    return setLoading(false);
  };

  return (
    <Modal
      show
      onClose={() => handleCloseModal()}
      title="Finalize Purchase"
      footer={
        <Container>
          <Row>
            <Col xs="auto ml-auto">
              <Button
                disabled={loading || purchaseError !== null}
                className="btn vw-button btn-white"
                onClick={() => handleCloseModal()}
              >
                <i className="fas fa-arrow-left mr-2" />
                Return and Edit
              </Button>
              <Button
                disabled={loading || !checked || !consent}
                className="vw-button btn btn-blue ml-3"
                onClick={() => handleClickPurchase()}
              >
                {loading ? "Please wait ... " : "Purchase"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Container className="review" fluid>
        <Row className="pb-3 mt-3">
          <Col xs={12} lg={{span: 6, order: 2}}>
            <div className="product-container">
              <div className="detail-item">
                <div className="detail-item_label">Product Name</div>
                <div className="name detail-item_value">
                  {product?.name || ""}
                </div>
              </div>
              <div className="detail-item">
                <div className="detail-item_label">Product Price</div>
                <div className="price detail-item_value">
                  {`$${Number(product.value)}`}
                </div>
              </div>
              <div className="detail-item">
                <div className="detail-item_label">Product Decription</div>
                <div className="desc detail-item_value">
                  {product?.description || ""}
                </div>
              </div>
              <div>
                <Button
                  disabled={loading || purchaseError !== null}
                  size="sm"
                  className="edit-product-button"
                  onClick={() => {
                    handleReturnToProductSelector()
                  }}
                >
                  Edit Product
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={{span: 6, order: 1}}>
            <section className="user-details_section mb-3">
              <div className="user-details_item">
                <div className="user-details_label">Name</div>
                <div className="user-details_value">{`${firstName} ${lastName}`}</div>
              </div>
              <div className="user-details_item">
                <div className="user-details_label">Email</div>
                <div className="user-details_value">{email}</div>
              </div>
              <div className="user-details_item">
                <div className="user-details_label">Mobile</div>
                <div className="user-details_value">{mobile}</div>
              </div>
              <div className="user-details_item">
                <div className="user-details_label">Address</div>
                <div className="user-details_value">
                  {`${unitNo || ""} ${streetNo || ""} ${suburb || ""} ${
                    state || ""
                  } ${postCode || ""}`}
                </div>
              </div>
              {(streetNoShipping ||
                addressShipping ||
                suburbShipping ||
                stateShipping ||
                postCodeShipping) && (
                <div className="user-details_item">
                  <div className="user-details_label">Shipping Address</div>
                  <div className="user-details_value">
                    {`${unitNoShipping || ""} ${streetNoShipping || ""} ${
                      addressShipping || ""
                    } ${suburbShipping || ""} ${stateShipping || ""} ${
                      postCodeShipping || ""
                    }`}
                  </div>
                </div>
              )}
            </section>
          </Col>
          <Col xs={12} lg={{order: 3, span: 12}}>
            <section className="card-details_section mb-3">
              <div className="card-details_item">
                <div className="card-details_label">Card Name</div>
                <div className="card-details_value">{cardHolderName}</div>
              </div>
              <div className="card-details_item">
                <div className="card-details_label">Card Number</div>
                <div className="card-details_value">
                  {`XXXX XXXX XXXX ${cardNumber.substring(
                    12,
                    cardNumber.length
                  )}`}
                </div>
              </div>
              <div className="card-details_item">
                <div className="card-details_label">Card Expiry</div>
                <div className="card-details_value">{`${cardExpiryMonth}/${cardExpiryYear}`}</div>
              </div>
            </section>
          </Col>
        </Row>
        <Row>
          {hardwareProductsDisplay.length > 0 && (
            <Col xs={12} className="pb-3">
              <section className="hardware-products_section">
                <div className="hardware-products_label pb-2">
                  Hardware Addons
                </div>
                <div className="hardware-products_value">
                  {hardwareProductsDisplay.map((product) => {
                    return (
                      <div
                        key={product.id}
                        className="hardware-products badge badge-pill badge-primary"
                      >
                        {product.name}
                        <span className="font-yellow ml-3 font-bold">
                          {`+$${product.value}${
                            product.once ? " Once off" : "/Month"
                          }`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </section>
            </Col>
          )}
          {addOnProductsDisplay.length > 0 && (
            <Col xs={12} className="pb-4">
              <section className="addon-products_section">
                <div className="addon-products_label pb-2">Addons</div>
                <div className="addon-products_value">
                  {addOnProductsDisplay.map((product) => {
                    return (
                      <div
                        key={product.id}
                        className="addon-products badge badge-pill badge-primary"
                      >
                        {product.name}
                        <span className="font-yellow ml-3 font-bold">
                          {`+$${product.value}${
                            product.once ? " Once off" : "/Month"
                          }`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </section>
            </Col>
          )}
        </Row>
        <Row className="payment-section_row pt-3">
          {serviceAvailability?.isNewConnection &&
            (newConnectionProduct?.value || 0) > 0 && (
              <Col xs={12} className="pb-2">
                <section className="development-charge_section">
                  <div>New Development Charge (Once Off)</div>
                  <div className="price">
                    {`$${newConnectionProduct?.value || 0}`}
                  </div>
                </section>
              </Col>
            )}
          <Col xs={12} className="pb-3">
            <section className="payment-section">
              <div className="initial pb-2">
                Initial Payment (Including First Month)
                <div className="value">
                  {`$${
                    selectedProducts.reduce(
                      (prev, value) => prev + (Number(value.value) || 0),
                      0
                    ) +
                    (serviceAvailability?.isNewConnection
                      ? newConnectionProduct?.value || 0
                      : 0)
                  }`}
                </div>
              </div>
              <div className="monthly">
                Monthly
                <div className="value">
                  {`$${selectedProducts
                    .filter(({once}) => !once)
                    .reduce(
                      (prev, value) => prev + (Number(value.value) || 0),
                      0
                    )}`}
                </div>
              </div>
            </section>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <section className="acknowledgement_section pt-4">
              <div className="vw-checkbox pb-1">
                <i
                  onClick={() => setConsent(!consent)}
                  style={{fontSize: 36}}
                  className={`mr-2 ${
                    consent ? "far fa-check-square" : "far fa-square"
                  }`}
                />
                <section className="text-justify">
                  {renderAcknowledgeText()}
                </section>
              </div>
              <div className="second-row">
                {showTerm && <Terms onClose={() => setTerm(false)} />}
                {showPolicy && (
                  <PrivacyPolicy onClose={() => setPolicy(false)} />
                )}
              </div>
              <div className="vw-checkbox">
                <i
                  onClick={() => setCheck(!checked)}
                  style={{fontSize: 36}}
                  className={`mr-2 ${
                    checked ? "far fa-check-square" : "far fa-square"
                  }`}
                />
                I agree to full
                <a onClick={() => setTerm(true)} className="mx-1">
                  Terms of Service
                </a>
                and
                <a onClick={() => setPolicy(true)} className="mx-1">
                  Privacy Policy
                </a>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default Review;
