import React, {Fragment, useRef} from "react";
import {useQuery, useMutation} from "@apollo/client";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import {
  Alert,
  Button,
  FormGroup,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import copy from "copy-to-clipboard";
import Input from "../bootstrap/input";
import Captcha from "../bootstrap/captcha";

import {getPageInfoQuery, getEwayClientKey} from "../logic/general";
import {
  createMobileVerificationMutation,
  createMobileVerificationResult,
  resendVerificationMutation,
  validateMobileVerificationMutation,
} from "../logic/mobile";
import {
  purchaseAndCreateUserMutation,
  getPurchaseResult,
} from "../logic/purchase";
import {Link} from "gatsby";
import Review from "../review";
import {getFormData, clearFormData, setFormData} from "../manager/form";

const FieldTemplate = (props) => {
  return (
    <Col md={6} {...props}>
      <FormGroup>{props.children}</FormGroup>
    </Col>
  );
};

const ProductPurchase = ({
  product,
  onSuccess,
  userData,
  setUserData,
  handleReturnToProductSelector,
}) => {
  const [isResend, setResend] = useAsyncSetState(false);
  const [isProcessing, setProcessing] = useAsyncSetState(false);
  const [showSuccessModal, setShowSuccessModal] = useAsyncSetState(false);
  const [showReview, setReview] = useAsyncSetState(false);
  const [showVerification, setVerification] = useAsyncSetState(false);
  const [credentials, setCredentials] = useAsyncSetState(null);
  const [purchaseError, setPurchaseError] = useAsyncSetState(null);
  const [captchaError, setCaptchaError] = useAsyncSetState(null);
  const [mobileVerificationError, setMobileVerificationError] =
    useAsyncSetState(null);
  const [validateMutation] = useMutation(validateMobileVerificationMutation);

  const [cmvMutation] = useMutation(createMobileVerificationMutation);
  const [rmvMutation] = useMutation(resendVerificationMutation);
  const [purchaseMutation] = useMutation(purchaseAndCreateUserMutation);
  const pageInfoQuery = useQuery(getPageInfoQuery);

  const formRef = useRef(null);
  const verificationCodeFormRef = useRef(null);

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const ewayKey = getEwayClientKey(pageInfoQuery);

  const formData = getFormData();
  const {building, unit, voucherCode, selectedProducts, purchaseFormInput} = formData || {};

  const initialData = {
    firstName: purchaseFormInput?.firstName || "",
    lastName: purchaseFormInput?.lastName || "",
    email: purchaseFormInput?.email || "",
    mobile: purchaseFormInput?.mobile || "",
    cardHolderName: purchaseFormInput?.cardHolderName || "",
    cardNumber: purchaseFormInput?.cardNumber || "",
    cardExpiryMonth: purchaseFormInput?.cardExpiryMonth || "",
    cardExpiryYear: purchaseFormInput?.cardExpiryYear || "",
    cardCCV: purchaseFormInput?.cardCCV || "",
  };

  const handleCopyPassword = (password) => {
    return copy(password, {
      debug: true,
      message: "Press #{key} to copy",
    });
  };

  const handleValidSubmit = async ({mobile, captcha, captchaKey}) => {
    try {
      const response = await cmvMutation({
        variables: {
          mobileNumber: mobile,
          captcha,
          captchaKey,
        },
        awaitRefetchQueries: true,
      });

      const {errors} = response;
      if (errors) {
        await setProcessing(false);
        return setCaptchaError(errors[0].message);
      }

      if (createMobileVerificationResult(response)) {
        await setCaptchaError(null);
        await setProcessing(false);
        return setVerification(true);
      } else {
        await setProcessing(false);
      }
    } catch (err) {
      console.log({err});
      if (err.length) {
        await setProcessing(false);
        return setCaptchaError(err[0].message);
      }

      await setProcessing(false);
      return setCaptchaError("Invalid captcha");
    }
    await setProcessing(false);
  };

  const handleCloseModal = () => {
    return setVerification(false);
  };

  const handlePurchase = async () => {
    try {
      const shippingAddress = {
        unitNo: userData?.unitNoShipping,
        streetNo: userData?.streetNoShipping,
        address: userData?.addressShipping,
        suburb: userData?.suburbShipping,
        state: userData?.stateShipping,
        postCode: userData?.postCodeShipping,
        countryId: building?.address.country.id,
      };
      let addresses = [];
      if ((selectedProducts || []).some(({type}) => type === "hardware")) {
        addresses.push(shippingAddress);
      }
      const data = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email.toString().trim(),
        mobileNumber: userData.mobile.toString().trim(),
        voucherCode,
        addresses,
        verificationCode: userData.code,
        streetNo: building.address.streetNo,
        address: building.address.address,
        suburb: building.address.suburb,
        postCode: building.address.postCode,
        state: building.address.state,
        building: unit,
        countryId: building.address.country.id,
        unitNo: unit,
        country: "au",
        buildingCode: building.code,
        products: (selectedProducts || []).map((p) => p.id),
        cardName: userData.cardHolderName,
        cardNumber: window.eCrypt.encryptValue(userData.cardNumber, ewayKey),
        cardCCV: window.eCrypt.encryptValue(userData.cardCCV, ewayKey),
        cardExpiry: `${userData.cardExpiryMonth}${userData.cardExpiryYear}`,
      };

      const response = await purchaseMutation({
        variables: {
          ...data,
        },
      });

      const result = getPurchaseResult(response);

      if ((result || {}).id) {
        await setProcessing(false);
        await setCredentials(result);
        await setUserData(null);
        await setPurchaseError(null);
        await setReview(false);
        clearFormData();
        return setShowSuccessModal(true);
      }
      await setProcessing(false);
      return setPurchaseError("An error has occured");
    } catch (err) {
      console.log(
        "err",
        typeof err,
        Object.keys(err),
        err.hasOwnProperty("error")
      );

      const errArray = {
        EBUILDING: "Building not found",
        EBUILDINGADDRESS: "Building address not found",
        EVOUCHER: "Invalid voucher",
        ESERVICE: "Service Unavailable",
        EACTIVE: "Service is active",
        EVERIFICATIONCODE: "Invalid verification code",
        EINVALIDPLANS: "Invalid Plans",
        EUNKNOWN: "An error has occured",
        "Email is already registered": "Email is already registered",
        "Invalid EWAY_CARDNUMBER": "Invalid card number",
        proper_email: "Please use a proper email",
        "Invalid captcha code provided": "Invalid captcha code provided",
      };

      let errorMessage =
        err.message.replace(/(GraphQL error:)/gi, "") || err.message;
      if (errArray[errorMessage]) {
        errorMessage = errArray[errorMessage];
      }
      await setProcessing(false);
      return setPurchaseError(errorMessage || "An error has occured");
    }
  };

  const handleHideSuccessModal = async () => {
    await setShowSuccessModal(false);
    return onSuccess && onSuccess();
  };

  const handleMobileVerificationSubmit = async (code) => {
    try {
      await setProcessing(true);
      const response = await validateMutation({
        variables: {
          mobileNumber: userData.mobile.toString().trim(),
          code: code,
        },
      });

      if (
        response?.data?.classMethods?.MobileVerification
          ?.validateMobileVerification
      ) {
        await setUserData({...userData, code});
        await setVerification(false);
        return setReview(true);
      } else {
        await setMobileVerificationError("Invalid verification code");
        await setProcessing(false);
      }
    } catch (err) {
      await setMobileVerificationError("Invalid Verification Code");
      await setProcessing(false);
    }
  };

  const handleVerificationCodeResend = async () => {
    if (verificationCodeFormRef) {
      verificationCodeFormRef.current.updateInputsWithValue({
        code: "", // Resets verification code value
      });
    }
    await setResend(true);
    await rmvMutation({
      variables: {
        mobileNumber: userData.mobile,
      },
    });

    return setTimeout(() => {
      return setResend(false);
    }, 2000);
  };

  const handlePurchaseFormSubmit = async (input) => {
    setFormData({ purchaseFormInput: input })
    await setUserData(input);
    return handleValidSubmit(input);
  };

  const handleRegenerateCaptcha = () => {
    setCaptchaError(null); // Resets captcha error
    if (formRef) {
      formRef.current.updateInputsWithValue({
        captcha: "", // Resets captcha value
      });
    }
  };

  return (
    <Fragment>
      {!!userData && showReview && (
        <Review
          purchaseError={purchaseError}
          product={product}
          submit={() => handlePurchase()}
          onClose={() => setReview(false)}
          handleReturnToProductSelector={handleReturnToProductSelector}
          {...userData}
        />
      )}
      {purchaseError && (
        <Modal centered show onHide={() => setPurchaseError(null)}>
          <Modal.Header>
            <Modal.Title>An error has occured</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col>{purchaseError}</Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="yellow vw-button"
              onClick={() => setPurchaseError(null)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showSuccessModal && (
        <Modal show onClose={() => handleHideSuccessModal()}>
          <Modal.Header bsPrefix="no-close-header" closeButton={false}>
            <Modal.Title className="pl-4 pr-4 pt-4">
              Purchase Success
              <hr />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row className="my-2">
                <Col xs={3}>Order ID: </Col>
                <Col className="credential-value">{credentials.id}</Col>
              </Row>
              <Row className="my-2">
                <Col xs={3}>{"Username"}</Col>
                <Col className="credential-value">{credentials.userName}</Col>
              </Row>
              <Row className="my-2">
                <Col xs={3}>Password</Col>
                <Col className="credential-value">
                  {credentials?.newPassword}
                  <Button
                    variant="vw-button px-1 py-0 ml-1"
                    onClick={() => handleCopyPassword(credentials?.newPassword)}
                  >
                    <i className="fas fa-copy" />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Link className="btn vw-button btn btn-blue mx-auto" to="/login">
              Back to Login
            </Link>
          </Modal.Footer>
        </Modal>
      )}
      {showVerification ? (
        <>
          <Modal show onHide={handleCloseModal}>
            <Formsy
              onValidSubmit={({code}) => handleMobileVerificationSubmit(code)}
              onInvalidSubmit={(e) => {
                console.log("error", e);
              }}
              ref={verificationCodeFormRef}
            >
              <Modal.Header>
                <Modal.Title>Confirm your mobile number</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>
                      <div className="alert alert-primary pb-2 pt-2 mb-2">
                        We have sent a text message to your phone, please enter
                        the code below
                      </div>
                    </Col>
                  </Row>
                  {isResend && <Row>
                    <Col xs="12">
                      <Alert variant="success">
                        New verification is sent!
                      </Alert>
                    </Col>
                  </Row>}
                  <Row className="mt-5 mb-2">
                    <Col>
                      <Input
                        name="code"
                        placeholder="Mobile Verification Code"
                        defaultValue=""
                        disabled={isProcessing}
                        required
                        noLabel
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Container className="d-flex">
                  <Button
                    className="vw-button btn btn-white mr-auto"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="vw-button btn btn-blue mr-2"
                    disabled={isResend || isProcessing}
                    onClick={() => handleVerificationCodeResend()}
                  >
                    {isResend ? "Success" : "Resend"}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isProcessing || purchaseError !== null}
                    className="vw-button btn btn-white"
                  >
                    {isProcessing ? "Please wait ... " : "Submit"}
                  </Button>
                </Container>
              </Modal.Footer>
            </Formsy>
          </Modal>
        </>
      ) : undefined}
      {mobileVerificationError && (
        <Modal centered show onHide={() => setMobileVerificationError(null)}>
          <Modal.Header>
            <Modal.Title>An error has occured</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col>{mobileVerificationError}</Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="yellow vw-button"
              onClick={() => setMobileVerificationError(null)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Formsy
        ref={formRef}
        onValidSubmit={(input) => handlePurchaseFormSubmit(input)}
        onInvalidSubmit={(e) => {
          console.log("error::invalid_purchase_details", e);
        }}
      >
        <Container className="my-1 purchase-container py-1">
          <Row
            style={{
              borderBottom: "1px solid #eee",
              paddingBottom: 2,
              marginBottom: 10,
            }}
          >
            <Col>
              <div className="main-text text-left py-2">
                Enter your details to purchase
              </div>
            </Col>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                name="firstName"
                noLabel
                placeholder="First Name"
                defaultValue=""
                value={initialData.firstName}
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="lastName"
                noLabel
                placeholder="Last Name"
                defaultValue=""
                value={initialData.lastName}
                required
              />
            </FieldTemplate>
          </Row>

          {(selectedProducts || []).some(({type}) => type === "hardware") && (
            <Fragment>
              <Row className="pt-2">
                <Col>
                  <hr className="white-divider" />
                  <div style={{marginBottom: "5px", marginTop: "-5px"}}>
                    Shipping Address
                  </div>
                </Col>
              </Row>
              <Row>
                <FieldTemplate md={2}>
                  <Input
                    name="unitNoShipping"
                    placeholder="Unit No"
                    noLabel
                    defaultValue={unit}
                  />
                </FieldTemplate>
                <FieldTemplate md={2}>
                  <Input
                    name="streetNoShipping"
                    noLabel
                    placeholder="Street Number"
                    defaultValue=""
                    value={`${building?.address?.streetNo || ""}`}
                    required
                  />
                </FieldTemplate>
                <FieldTemplate md={8}>
                  <Input
                    name="addressShipping"
                    noLabel
                    placeholder="Street Name"
                    defaultValue=""
                    value={`${building?.address?.address || ""}`}
                    required
                  />
                </FieldTemplate>
              </Row>
              <Row>
                <FieldTemplate md={3}>
                  <Input
                    name="suburbShipping"
                    type="text"
                    noLabel
                    placeholder="City"
                    value={`${building?.address?.suburb}`}
                    required
                  />
                </FieldTemplate>
                <FieldTemplate md={3}>
                  <Input
                    name="stateShipping"
                    type="text"
                    noLabel
                    placeholder="State"
                    value={`${building?.address?.state}`}
                    required
                  />
                </FieldTemplate>
                <FieldTemplate md={3}>
                  <Input
                    name="countryShipping"
                    type="text"
                    noLabel
                    placeholder="Country"
                    value={`${building?.address?.country?.name}`}
                    disabled
                    required
                  />
                </FieldTemplate>
                <FieldTemplate md={3}>
                  <Input
                    name="postCodeShipping"
                    type="text"
                    noLabel
                    placeholder="Postal Code"
                    value={`${building?.address?.postCode}`}
                    required
                  />
                </FieldTemplate>
              </Row>
              <Row>
                <Col>
                  <hr className="white-divider" />
                </Col>
              </Row>
            </Fragment>
          )}

          <Row>
            <FieldTemplate md={2}>
              <Input
                name="unitNo"
                disabled
                placeholder="Unit No"
                noLabel
                defaultValue={unit}
              />
            </FieldTemplate>
            <FieldTemplate md={10}>
              <Input
                name="streetNo"
                noLabel
                placeholder="Street"
                defaultValue=""
                disabled
                value={`${building?.address?.streetNo} ${building?.address?.address}`}
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate md={3}>
              <Input
                name="suburb"
                type="text"
                noLabel
                placeholder="City"
                value={`${building?.address?.suburb}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={3}>
              <Input
                name="state"
                type="text"
                noLabel
                placeholder="State"
                value={`${building?.address?.state}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={3}>
              <Input
                name="country"
                type="text"
                noLabel
                placeholder="Country"
                value={`${building?.address?.country?.name}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={3}>
              <Input
                name="postCode"
                type="text"
                noLabel
                placeholder="Postal Code"
                value={`${building?.address?.postCode}`}
                disabled
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                type="email"
                name="email"
                noLabel
                placeholder="Email Address"
                validations={{
                  isEmail: true,
                }}
                validationErrors={{
                  isEmail: "Please input valid email address",
                }}
                defaultValue=""
                value={initialData.email}
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="mobile"
                noLabel
                placeholder="Mobile Number"
                defaultValue=""
                required
                validations={{
                  isNumeric: true,
                }}
                validationErrors={{
                  isNumeric: "Please input valid mobile number",
                }}
                value={initialData.mobile}
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                name="cardHolderName"
                noLabel
                placeholder="Card Holder Name"
                validations={{
                  maxLength: 50,
                  matchRegexp: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
                }}
                validationErrors={{
                  maxLength: "You can not type in more than 50 characters",
                  matchRegexp: "Enter the name on your credit card",
                }}
                defaultValue=""
                required
                value={initialData.cardHolderName}
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="cardNumber"
                noLabel
                placeholder="Card Number"
                validations={{
                  isNumeric: true,
                  maxLength: 16,
                  minLength: 16,
                }}
                validationErrors={{
                  isNumeric: "You can only input numerical characters",
                  maxLength: "You can not input more than 16 characters",
                  minLength: "You can not input less than 16 characters",
                }}
                defaultValue=""
                required
                value={initialData.cardNumber}
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate md={4}>
              <Input
                name="cardExpiryMonth"
                noLabel
                placeholder="Card Expiry Month (MM)"
                validations={{
                  isNumeric: true,
                  maxLength: 2,
                  minLength: 2,
                  invalidMonthValue: (__, value) => {
                    return value <= 12 && value > 0; // Checks if valid month
                  },
                  isExpired: (values, value) => {
                    const currentMonth = new Date()
                      .getMonth()
                      .toString()
                      .slice(-2);
                    const currentYear = new Date()
                      .getFullYear()
                      .toString()
                      .slice(-2);

                    return (
                      // Validates if card is expired
                      values.cardExpiryYear === undefined ||
                      values.cardExpiryYear.toString().trim() === "" ||
                      Number(values.cardExpiryYear) > Number(currentYear) ||
                      Number(
                        currentMonth > 10 ? `0${currentMonth}` : currentMonth
                      ) <= Number(value)
                    );
                  },
                }}
                validationErrors={{
                  minLength: "You can not input less than 2 characters",
                  maxLength: "You can not input more than 2 characters",
                  isNumeric: "You can only input numerical characters",
                  invalidMonthValue: "Cannot input invalid month",
                  isExpired: "Card already expired",
                }}
                defaultValue=""
                required
                value={initialData.cardExpiryMonth}
              />
            </FieldTemplate>
            <FieldTemplate md={4}>
              <Input
                name="cardExpiryYear"
                noLabel
                placeholder="Card Expiry Year (YY)"
                validations={{
                  isNumeric: true,
                  maxLength: 2,
                  minLength: 2,
                  invalidYearValue: (__, value) => {
                    const currentYear = new Date()
                      .getFullYear()
                      .toString()
                      .slice(-2);

                    return Number(currentYear) <= value;
                  },
                }}
                validationErrors={{
                  minLength: "You can not type in less than 2 characters",
                  maxLength: "You can not type in more than 2 characters",
                  isNumeric: "You can only input numerical characters",
                  invalidYearValue: "Cannot input year less than current year",
                }}
                defaultValue=""
                required
                value={initialData.cardExpiryYear}
              />
            </FieldTemplate>
            <FieldTemplate md={4}>
              <Input
                name="cardCCV"
                noLabel
                placeholder="CCV"
                defaultValue=""
                validations={{
                  isNumeric: true,
                  minLength: 3,
                  maxLength: 3,
                }}
                validationErrors={{
                  isNumeric: "You can only input numerical characters",
                  minLength: "You can not type in less than 3 characters",
                  maxLength: "You can not type in more than 2 characters",
                }}
                required
                value={initialData.cardCCV}
              />
            </FieldTemplate>
          </Row>
          <Row>
            <Col xs={12} md="auto" className="ml-auto">
              <Captcha
                inline
                onRegenerateCaptcha={() => handleRegenerateCaptcha()}
              />
            </Col>
          </Row>
          {captchaError && (
            <Row>
              <Col xs="6 ml-auto">
                <Alert className="my-3" variant="danger">
                  {captchaError}
                </Alert>
              </Col>
            </Row>
          )}
          <Row className="mt-2 mb-2">
            <Col xs="auto" className="ml-auto">
              <button type="submit" className="vw-button btn btn-blue">
                Continue
              </button>
            </Col>
          </Row>
        </Container>
      </Formsy>
    </Fragment>
  );
};

export default ProductPurchase;
