import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";

import TextField from "./input";
import FormsyHiddenField from "./hidden";
import {fetch} from "window-or-global";

export default function Captcha(props) {
  const [captchaKey, setCaptchaKey] = useState();
  const baseUrl = process.env.BACKEND_URL;
  const {onRegenerateCaptcha} = props;

  async function generateCaptcha() {
    if (captchaKey && captchaKey !== "") {
      await fetch(`${baseUrl}captcha.api/destroy/${captchaKey}`);
    }

    const urlGenerate = `${baseUrl}captcha.api/generate`;
    const capGenRes = await fetch(urlGenerate);
    if (capGenRes.status === 200) {
      const {key} = await capGenRes.json();
      setCaptchaKey(key);
    }
  }

  useEffect(() => {
    generateCaptcha();
  }, []);

  if (!captchaKey) {
    return <React.Fragment />;
  }
  
  let uri = `${baseUrl}captcha.api/image/${captchaKey}`;

  const handleCaptchaRefresh = () => {
    generateCaptcha();

    if (onRegenerateCaptcha) {
      onRegenerateCaptcha();
    }
  }

  return (
    <>
      <FormsyHiddenField name="captchaKey" val={captchaKey} />
      <div className="vw-captcha-container">
        <div className="vw-captcha-image">
          <a
            className="vw-button"
            onClick={() => {
              handleCaptchaRefresh()
            }}
          >
            <i
              style={{
                transform: "translateY(20%)",
                marginRight: 10,
                cursor: "pointer",
                color: "#2769c9",
              }}
              className="captcha-refresh fa fa-sync "
            />
          </a>
          <img
            alt=""
            key={captchaKey}
            src={uri}
            className="img-fluid pull-left"
          />
        </div>
        <div className="vw-captacha-input">
          <TextField
            name="captcha"
            label="Captcha"
            type="text"
            fullWidth
            pattern="[a-zA-Z0-9]+"
            inputMode="numeric"
            placeholder="Enter captcha"
            autoComplete="off"
            required
          />
        </div>
      </div>
      {/* </Container> */}
    </>
  );
}
